import classNames from 'classnames';
import React from 'react';
import { useRouter } from 'next/router';

const QUOTATION_ANCHOR = '#devis';

export const StickySocial = ({ layout }) => {
  const router = useRouter();

  const isTeamBuildinPage = router?.asPath?.startsWith?.(
    '/team-building-paris'
  );

  const socialLinks = layout.data.socialProfiles
    ? layout.data.socialProfiles.split('\n')
    : [];

  const socialIcons = {
    facebook: '/icons/social/facebook.svg',
    'x.com': '/icons/social/X.svg',
    instagram: '/icons/social/instagram.svg',
    tiktok: '/icons/social/tiktok.svg',
    youtube: '/icons/social/youtube.svg',
  };

  const socialList = Object.entries(socialIcons)
    .filter(([key]) => socialLinks.some((link) => link.includes(key)))
    .map(([key, icon]) => (
      <li
        className={classNames(
          'sticky-social__list__item',
          `sticky-social__list__item--${key}`
        )}
        key={key}
      >
        <a
          href={socialLinks.find((link) => link.includes(key))}
          target="_blank"
          rel="noopener"
        >
          <img
            src={icon}
            alt={key}
            draggable={false}
            width="20px"
            height="20px"
          />
        </a>
      </li>
    ));

  const handleQuotationClick = (event) => {
    const target = document.querySelector(QUOTATION_ANCHOR);

    if (target) {
      event.preventDefault();

      const targetScrollOffsetTop =
        parseInt(
          window
            .getComputedStyle(target)
            .getPropertyValue('--js-scroll-offset-top') || '0',
          10
        ) || 0;

      const offset = targetScrollOffsetTop;

      window.scrollTo({
        top:
          document.scrollingElement.scrollTop +
          target.getBoundingClientRect().top -
          offset,
        behavior: 'smooth',
      });
    }
  };

  return isTeamBuildinPage ? (
    <a
      href={QUOTATION_ANCHOR}
      onClick={handleQuotationClick}
      className="sticky-social sticky-social--team-building"
    >
      Faites-vous
      <br />
      rappeler
    </a>
  ) : // <div className="sticky-social">
  //   <ul className="sticky-social__list">{socialList}</ul>
  // </div>
  null;
};
